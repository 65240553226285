<template>
  <b-container>
    <b-navbar
      toggleable="lg"
      type="dark"
      variant="default"
      :style="{
        'justify-content': $vssWidth > 767 ? 'space-between' : 'center',
      }"
    >
      <b-navbar-brand href="/">
        <b-img src="/img/Union.png" class="nav-logo"></b-img>
        <span class="text-secondary h2 nav-logo-text">SAPPHIRE SOFTWARE</span>
      </b-navbar-brand>

      <div class="d-flex">
        <b-navbar-brand @click="GoSection('about')" class="nav-box">
          <span class="nav-item-text">ABOUT US</span>
        </b-navbar-brand>

        <b-navbar-brand @click="GoSection('service')" class="nav-box">
          <span class="nav-item-text">SERVICE</span>
        </b-navbar-brand>

        <b-navbar-brand @click="GoSection('tech')" class="nav-box">
          <span class="nav-item-text">OUR TECH</span>
        </b-navbar-brand>
      </div>

      <div
        class="contactus"
        :style="{ display: $vssWidth > 991 ? 'block' : 'none' }"
      >
        <span>Contact Us</span>
      </div>
    </b-navbar>
  </b-container>
</template>

<script>
export default {
  components: {},
  data: () => ({}),

  methods: {
    GoSection(section) {
      this.$emit("scroll-to-section", section);
    },
  },
};
</script>

<style scoped>
.nav-logo {
  width: 30px;
}
.nav-logo-text {
  padding: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;

  color: #4c6378;
}

.nav-box {
  font-size: 17px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  color: #4c6378;
  padding: 5px 10px;
   transition: 0.2s;

}

.nav-box:hover {
  /* background-color: #4c6378; */
  color: #4c6378 !important;
  border-radius: 3px;
 transform: translateY(-5px);
}

.contactus {
  background: linear-gradient(99.13deg, #1088d6 6.21%, #1256d3 91.79%),
    linear-gradient(122.29deg, #13e3b1 14.46%, #0c94b1 78.74%),
    linear-gradient(113.74deg, #f9ab7d 27.92%, #f95e70 69.53%);
  border-radius: 100px;
  color: #fff;
  box-shadow: -2px -4px 0px 0px #00000040 inset;
  width: 175px;
  text-align: center;
  font-size: 15px;
  padding: 10px 0px;
  font-weight: 600;
}
</style>
